@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* Desktop */
  .cell-attr {
    @apply border-b border-b-border px-2 py-1 text-primary rtl:text-border;
  }

  .last-row {
    @apply border-none;
  }

  .hide-overflow-text {
    @apply overflow-hidden text-ellipsis whitespace-nowrap;
  }

  .btnHeader {
    @apply cursor-pointer bg-white bg-opacity-10 hover:bg-opacity-25;
  }

  .btnCtrls {
    @apply relative flex h-16 w-full flex-col items-center justify-center gap-1 rounded-xl bg-primary bg-opacity-10 hover:cursor-pointer hover:bg-opacity-25;
  }

  .btnCtrls div {
    @apply text-xs text-white;
  }

  .modifyDetailNav [data-name] {
    @apply hidden;
  }

  .modifyDetailNav [data-detailsection] {
    @apply flex-row gap-1 text-xl;
  }

  .modifyDetailNav ul {
    @apply !mt-5;
  }

  .mobileTiles {
    @apply bg-mobileTile rounded-lg bg-opacity-20 p-3 hover:bg-opacity-30;
  }

  .mobileTiles div:nth-child(2) {
    @apply text-mobileLinks overflow-hidden text-ellipsis whitespace-nowrap text-base;
  }
}
